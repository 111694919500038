<template>
	<div class="mhome">
		<div class="mhome-wrap">
			<div class="mhome-head">
				<div class="prev"></div>
				<div class="title">{{$t('mobile.order.cartText1')}}<span>({{cartTotal}})</span></div>
				<div class="action">
					<span @click="handleCartAction">{{actionType?$t('mobile.actions.finish'):$t('mobile.actions.manage')}}</span>
				</div>
			</div>
			<div class="mhome-block-top"></div>
			<div class="mhome-main">
				<div class="mhome-cart" v-for="(item,index) in cartList" :key="index">
					<div class="cart-head">
						<div class="check">
							<el-checkbox v-model="checkStore" :label="item.store_id"
								@change="handleShopCheck($event,index)">{{ }}</el-checkbox>
						</div>
						<div class="title" @click="$router.push({name:'mStoreInfo',params:{id:item.store_id}})">
							{{item.store_name}}<i class="el-icon-arrow-right"></i>
						</div>
					</div>
					<el-checkbox-group v-model="checkCart" @change="handleCartCheck($event,index)">
						<div class="cart-goods" v-for="(goods,index2) in item.goods_list" :key="index2">
							<div class="item-check">
								<el-checkbox :label="goods.cart_id">{{ }}</el-checkbox>
							</div>
							<div class="item-wrap">
								<div class="item-pic"
									@click="$router.push({name:'mGoodsInfo',params:{id:goods.goods_id}})"><img
										:src="goods.goods_image" @error="imageLoadError" alt=""></div>
								<div class="item-main">
									<div class="name"
										@click="$router.push({name:'mGoodsInfo',params:{id:goods.goods_id}})">
										{{goods.goods_name}}</div>
									<div class="specs"
										@click="$router.push({name:'mGoodsInfo',params:{id:goods.goods_id}})">
										{{goods.specification}}</div>
									<div class="item-box">
										<div class="price"
											@click="$router.push({name:'mGoodsInfo',params:{id:goods.goods_id}})">
											￥<span>{{goods.price}}</span></div>
										<div class="minput-number">
											<div class="number-decrease" :class="goods.quantity==1?'is-disabled':''"
												@click="handleMinusGoods(goods)"><i class="el-icon-minus"></i></div>
											<input class="number-inner" type="text" disabled="disabled"
												v-model="goods.quantity">
											<div class="number-increase"
												:class="goods.quantity==goods.stock?'is-disabled':''"
												@click="handlePlusGoods(goods)"><i class="el-icon-plus"></i></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</el-checkbox-group>
				</div>
			</div>
			<div class="mhome-block-bottom"></div>
			<div class="mhome-bottom">
				<div class="mfooter-nav">
					<el-checkbox v-model="checkAll" @change="handleCheckAll">{{$t('mobile.checkbox.checkAll')}}</el-checkbox>
					<div class="nav-left">
						<div class="btn" @click="handleCartDelete" v-if="actionType">{{$t('mobile.actions.delete')}}</div>
						<template v-else>
							<div class="text">{{$t('mobile.order.total')+'：'}}</div>
							<div class="price">
								￥<span>{{cartAmount>0?cartAmount:0}}</span></div>
							<div class="btn" @click="doOrderConfirm">{{$t('mobile.actions.settlement')}}({{checkCart.length}})</div>
						</template>
					</div>
				</div>
				<footer-nav></footer-nav>
			</div>
		</div>
	</div>
</template>

<script>
	import footerNav from '@/components/mobile/footer';
	import { getCartList, deleteCart, totalCart, createCart } from '@/api/mobile/order';
	export default {
		components: { footerNav },
		data() {
			return {
				cartList: [],
				cartTotal: 0,
				checkStore: [],
				checkCart: [],
				checkAll: false,
				cartAmount: 0.00,
				actionType: false,
			}
		},
		mounted() {
			this.getCartList();
		},
		methods: {
			imageLoadError(e) {
				e.target.src = this.$imageErrorGoods;
				e.target.style.objectFit = 'fill';
			},
			getCartList() {
				let loading = this.$loading();
				let param = {
					page_index: 1,
					page_size: 100
				}
				getCartList(param).then(response => {
					loading.close();
					this.cartList = response.data.list;
					this.cartTotal = parseInt(response.data.total);
				}).catch(() => {
					loading.close();
				})
			},
			handleCartAction() {
				this.actionType = this.actionType ? false : true;
			},
			handleCartDelete() {
				this.$confirm(this.$t('mobile.confirm.deleteCartText1') + this.checkCart.length + this.$t('mobile.confirm.deleteCartText2'), '', {
					confirmButtonText: this.$t('mobile.actions.delete'),
					cancelButtonText: this.$t('mobile.actions.think'),
					center: true,
					customClass: 'wap-message-confirm',
				}).then(() => {
					deleteCart({cart_id: this.checkCart.join(',')}).then(() => {
						this.checkStore = [];
						this.checkCart = [];
						this.checkAll = false;
						this.getCartList();
					})
				}).catch(() => {
				});
			},
			handleShopCheck(val, index) {
				let cart = JSON.parse(JSON.stringify(this.cartList[index]));
				if (this.checkStore.includes(cart.store_id)) {
					cart.goods_list.forEach(goods => {
						if (!this.checkCart.includes(goods.cart_id)) {
							this.checkCart.push(goods.cart_id);
						}
					})
				} else {
					cart.goods_list.forEach(goods => {
						if (this.checkCart.includes(goods.cart_id)) {
							this.checkCart.splice(this.checkCart.indexOf(goods.cart_id), 1);
						}
					})
				}
				this.checkAll = this.checkStore.length === this.cartList.length;
				this.onCartAmount();
			},
			handleCartCheck(val, index) {
				let cart = JSON.parse(JSON.stringify(this.cartList[index]));
				let flag = 1;
				cart.goods_list.forEach(goods => {
					if (!this.checkCart.includes(goods.cart_id)) {
						flag = 2;
					}
				})
				if (flag == 1 && !this.checkStore.includes(cart.store_id)) {
					this.checkStore.push(cart.store_id)
				} else if (flag == 2 && this.checkStore.includes(cart.store_id)) {
					this.checkStore.splice(this.checkStore.indexOf(cart.store_id), 1);
				}
				this.checkAll = this.checkStore.length === this.cartList.length;
				this.onCartAmount();
			},
			handleCheckAll(val) {
				if (val) {
					this.cartList.forEach(item => {
						if (!this.checkStore.includes(item.store_id)) {
							this.checkStore.push(item.store_id)
						}
						item.goods_list.forEach(goods => {
							if (!this.checkCart.includes(goods.cart_id)) {
								this.checkCart.push(goods.cart_id)
							}
						})
					})
					this.onCartAmount();
				} else {
					this.checkStore = [];
					this.checkCart = [];
					this.cartAmount = 0;
				}
			},
			onCartAmount() {
				if(this.checkCart.length>0){
					totalCart({cart_ids: this.checkCart}).then(response => {
						this.cartAmount = response.data.total;
					})
				}else {
					this.cartAmount = 0;
				}
			},
			handleMinusGoods(goods) {
				if (goods.quantity > 1) {
					goods.quantity--;
					this.onNumberGoods(goods);
				}
			},
			handlePlusGoods(goods) {
				if (goods.quantity < goods.stock) {
					goods.quantity++;
					this.onNumberGoods(goods);
				}
			},
			onNumberGoods(goods) {
				createCart(goods).then(()=> {
				})
			},
			doOrderConfirm() {
				if (this.checkCart.length > 0) {
					this.$router.push({
						name: "mConfirmOrder",
						query: {
							from: 'cart',
							cart_ids: this.checkCart.join(','),
						}
					});
				} else {
					this.$message({
						message: this.$t('mobile.errorMsg.selectGoods'),
						customClass: 'wap-message',
						type: 'error'
					});
				}
			},
		}
	}
</script>
<style lang="scss" scoped>
	.mhome-wrap {
		::v-deep .el-checkbox {
			.el-checkbox__input {
				.el-checkbox__inner {
					width: 0.512rem;
					height: 0.512rem;
					border-radius: 50%;
					&:after {
						top: 0.064rem;
						left: 0.16rem;
						width: 0.096rem;
						height: 0.224rem;
						border-right-width: 0.032rem;
						border-bottom-width: 0.032rem;
					}
				}
				&.is-checked {
					.el-checkbox__inner {
						background-color: #f25528;
						border-color: #f25528;
					}
				}
			}
			
			.el-checkbox__label {
				color: #606266;
				font-size: 0.416rem;
				line-height: 0.512rem;
				vertical-align: middle;
			}
		}
	}

	.mhome-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 1.28rem;
		line-height: 0.768rem;
		background-color: #FFFFFF;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 9;
		position: absolute;
		box-sizing: border-box;
		
		.prev {
			width: 1.28rem;
			height: 1.28rem;
			line-height: 1.28rem;
			font-size: 0.64rem;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			color: #777777;
		}
		.title {
			font-weight: bold;
			font-size: 0.544rem;
			display: inline-block;
			vertical-align: middle;

			span {
				font-size: 0.416rem;
				font-weight: normal;
			}
		}

		.action {
			color: #777777;
			font-size: 0.384rem;
			margin-right: 0.384rem;
		}
	}

	.mhome-main {
		padding: 0.32rem 0.192rem;
	}

	.mhome-cart {
		border-radius: 0.256rem;
		padding: 0.256rem 0.384rem;
		background-color: #FFFFFF;
		margin-bottom: 0.32rem;

		.cart-head {
			display: flex;
			padding: 0.192rem 0 0.128rem;
			line-height: 0.64rem;

			.check {
				margin-right: 0.16rem;
			}

			.title {
				font-size: 0.448rem;
				font-weight: bold;
			}

			.el-icon-arrow-right {
				color: #999999;
			}
		}

		.cart-goods {
			padding: 0.16rem 0;
			display: flex;

			.item-check {
				display: flex;
				align-items: center;
				margin-right: 0.16rem;
			}

			.item-wrap {
				flex: 1;
				width: 0;
				display: flex;
			}

			.item-pic {
				width: 2.88rem;
				height: 2.88rem;
				overflow: hidden;
				border-radius: 0.256rem;
				margin-right: 0.32rem;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.item-main {
				flex: 1;
				width: 0;

				.name {
					height: 0.768rem;
					line-height: 0.768rem;
					font-size: 0.416rem;
					font-weight: bold;
					overflow: hidden;
					margin-bottom: 0.128rem;
				}

				.specs {
					font-size: 0.384rem;
					color: #999999;
					line-height: 0.544rem;
					margin-bottom: 0.256rem;
				}

				.price {
					color: #FF5000;
					font-size: 0.416rem;
					line-height: 0.832rem;

					span {
						font-size: 0.512rem;
						font-weight: bold;
					}
				}
			}

			.item-box {
				display: flex;
				justify-content: space-between;
			}

			.minput-number {
				float: right;
				display: inline-block;
				height: 0.864rem;
				line-height: 0.832rem;
				box-sizing: border-box;
				border: 1px solid #DCDFE6;
				border-radius: 0.096rem;
				overflow: hidden;

				.number-decrease,
				.number-increase {
					color: #555555;
					text-align: center;
					font-size: 0.448rem;
					height: 100%;
					width: 0.864rem;
					display: inline-block;
					vertical-align: top;
					// background-color: #F5F7FA;
				}

				.number-decrease {
					border-right: 1px solid #DCDFE6;
				}

				.number-increase {
					border-left: 1px solid #DCDFE6;
				}

				.number-decrease.is-disabled,
				.number-increase.is-disabled {
					color: #c0c4cc;
				}

				.number-inner {
					height: 100%;
					width: 1.408rem;
					display: inline-block;
					vertical-align: top;
					text-align: center;
					font-size: 0.416rem;
					padding: 0 0.224rem;
					box-sizing: border-box;
				}
			}
		}
	}
	.mhome-block-top {
		height: 1.28rem;
	}
	.mhome-block-bottom {
		height: 2.912rem;
	}
	.mfooter-nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #FFFFFF;
		box-shadow: 0 -1px 1px #E5E5E5;
		padding: 0.16rem 0.384rem;

		.nav-left {
			display: flex;
			align-items: center;
		}

		.text {
			font-weight: bold;
			font-size: 0.416rem;
			color: #333333;
			margin-right: 0.096rem;

			span {
				color: #999999;
			}
		}

		.price {
			color: #fa842c;
			font-size: 0.416rem;
			margin-right: 0.256rem;

			span {
				font-weight: bold;
				font-size: 0.576rem;
			}
		}

		.btn {
			height: 1.152rem;
			line-height: 1.152rem;
			width: 3.2rem;
			text-align: center;
			font-size: 0.448rem;
			display: inline-block;
			vertical-align: top;
			border-radius: 0.576rem;
			color: #FFFFFF;
			font-weight: bold;
			border-radius: 0.576rem;
			background: linear-gradient(to right, #ee7e32, #f25528);
		}
	}
</style>
