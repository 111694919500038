<template>
	<div class="mfooter">
		<!-- <div class="item" :class="{active: navActive('home')}">
			<div class="item-icon"><svg-icon :icon-class="navActive('home')?'home_fill':'home'"></svg-icon></div>
			<div class="item-name">首页</div>
		</div> -->
		<!-- <div class="item">
			<div class="item-icon"></div>
			<div class="item-name">分类</div>
		</div> -->
		<router-link :to="{ name: 'mShoppingCart' }" class="item" :class="{active: navActive('cart')}">
			<div class="item-icon"><svg-icon :icon-class="navActive('cart')?'cart_fill':'cart'"></svg-icon></div>
			<div class="item-name">购物车</div>
		</router-link>
		<router-link :to="{ name: 'mUserCenter' }" class="item" :class="{active: navActive('people')}">
			<div class="item-icon"><svg-icon :icon-class="navActive('people')?'people_fill':'people'"></svg-icon></div>
			<div class="item-name">我的</div>
		</router-link>
	</div>
</template>

<script>
	export default {
		methods: {
			navActive(type) {
				const { name, meta } = this.$route
				if(meta.activeMenu) {
					return type===meta.activeMenu
				}
				return type===name
			}
		},
	}
</script>

<style lang="scss" scoped>
	.mfooter {
		display: flex;
		border-top: 1px solid #e5e5e5;
		background-color: #ffffff;
		.item {
			flex: 1;
			color: #333333;
			padding: 0.128rem 0;
			text-align: center;
			.item-icon {
				margin: 0 auto;
				font-size: 0.768rem;
				background-size: 100% auto;
				background-repeat: no-repeat;
			}
			.item-name {
				font-size: 0.32rem;
				line-height: 1em;
				margin-top: 0.096rem;
			}
			&.active {
				color: #ff5000;
			}
		}
	}
</style>